import { useState, useEffect, useRef } from "react";
import {isMobile} from 'react-device-detect';
import axios from 'axios'
import Marquee from "react-fast-marquee";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal'

import {
  Facebook,
  Instagram,
  Linkedin,
  Tiktok,
  Envelope,
  Whatsapp,
  CaretDownFill,
} from "react-bootstrap-icons";

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import productsData from '../products.json';

import img1 from "../img/carrucel/productos.png";
import img2 from "../img/carrucel/impresos.png";
import img3 from "../img/carrucel/temprada.png";

import val1 from "../img/valor1.png";
import val2 from "../img/valor2.png";
import val3 from "../img/valor3.png";

import assekuransa from "../img/clients/assekuransa.png"
import caxi from "../img/clients/caxi.png"
import cedros from "../img/clients/cedros.png"
import cristal from "../img/clients/cristal.png"
import dnatural from "../img/clients/dnatural.png"
import little from "../img/clients/little.png"
import master from "../img/clients/master.png"
import materias from "../img/clients/materias.png"
import mnkey from "../img/clients/mnkey.png"
import porton from "../img/clients/porton.png"
import quest from "../img/clients/quest.png"
import urbania from "../img/clients/urbania.png"
import wtracking from "../img/clients/wtracking.png"

import promos from "../img/services/promos.png"
import proyectos from "../img/services/proyectos.png"
import regalos from "../img/services/regalos.png"

import item1 from "../img/services/item1.png"
import item2 from "../img/services/item2.png"
import item3 from "../img/services/item3.png"
import promo_d from "../img/services/promo_d.png"
import proyectos_d from "../img/services/proyectos_d.png"
import regalos_d from "../img/services/regalos_d.png"
import close1 from "../img/services/close1.png"
import close2 from "../img/services/close2.png"
import close3 from "../img/services/close3.png"

import logowhite from "../img/m_logo.png";
import logogrupo from "../img/mebs_b.png";
import back from "../img/back.png"
import mandarina from "../img/animations/mandarina.json";

function Home() {
  const refHome = useRef();
  const refUs = useRef();
  const refCatalog = useRef();
  const refContact = useRef();
  const refClients = useRef();
  const [jsonData, setJsonData] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(15);
  const [showFilterMenu, setShowFilterMenu] = useState(true);
  const [ filteredProducts, setFilteredProducts ] = useState([])
  const [ search, setSearch ] = useState('')

  const [isLoading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [comments, setComments] = useState("")
  const [lgShow, setLgShow] = useState(false)

  const [showMessage, setShowMessage] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  const [showServices, setShowServices] = useState(false)
  const [showCatalog, setShowCatalog] = useState(false)
  const [showServiceItem, setShowServiceItem] =useState(false)
  const [serviceItemId, setServiceItemId] =useState(1)
  const [serviceItemTitle, setServiceItemTitle] =useState('Artículos Promocionales')
  const [serviceItemDesc, setServiceItemDesc] =useState('Disponemos de una amplia gama de productos para reforzar la identidad de tu marca y captar la atención del público.')
  const [serviceItemImage, setServiceItemImage] = useState(promo_d)
  const [serviceItemColor, setServiceItemColor] = useState('#F48214')
  const [serviceItemBackground, setServiceItemBackground] = useState('#F48214')
  const [serviceItemClose, setServiceItemClose] = useState(close1)

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6LcUsEMoAAAAAA8AApraXZxhHWX_cbkxXJb8LwfZ`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /*useEffect(() => {
    setFilteredProducts( selectedCategories.length
    ? productsData.product.filter((product) =>
        selectedCategories.includes(product.categories.category_ref_1)
      )
    : ( search ? filteredProducts : productsData.product ) )
  }, [ selectedCategories ]);

  useEffect(() => {
    setSelectedCategories( [] )
    setFilteredProducts( productsData.product.filter((product) =>
      (product.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")).includes( search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") )
    ))
  }, [ search ]);

  const handleCategoryToggle = (categoryNumber) => {
    setSelectedCategories(
      selectedCategories.includes(categoryNumber)
        ? selectedCategories.filter((cat) => cat !== categoryNumber)
        : [...selectedCategories, categoryNumber]
    );
    setVisibleProducts(15); // Reset visible products when changing category
  };

  const handleCategoryToggleBar = (categoryNumber) => {
    if( categoryNumber == -1 ) setSelectedCategories([])
    else {
      var cats = [] 
      cats = cats.includes(categoryNumber)
        ? cats.filter((cat) => cat !== categoryNumber)
        : [...cats, categoryNumber]
      setSelectedCategories( cats );
    }

    setVisibleProducts(15); // Reset visible products when changing category
  };*/

  const categoryMapping = {
    'Novedades 2023': '96',
    //'Covid-19': '25',
    'Tecnología': '87',
    'Oficinas y Negocio': '89',
    'Regalos y Premium': '4'
  };

  //const categories = Object.keys(categoryMapping);

  const loadMore = () => {
    setVisibleProducts((prevVisibleProducts) =>
      Math.min(prevVisibleProducts + 15, filteredProducts.length)
    );
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      loadMore();
    }
  };

  /*const toggleFilterMenu = () => {
    setShowFilterMenu(!showFilterMenu);
  };*/

  const handleClick = (eventKey) => {
    //if( path == "/" || eventKey == "refContact" ) {
    var element = eval(eventKey).current;
    var headerOffset = 110;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    /*} else 
        window.location.href = "/?s=" + eventKey*/
  };
  const [activeTab, setActiveTab] = useState(-1);

  const handleTabSelect = (eventKey) => {
    if (eventKey === "desc") {
      window.location.href = "https://mandarinapromocionales.mx";
    } else {
      setActiveTab(eventKey);
    }
  };

  const handleShow = () => setShowMessage(true);
  
  const closeModalSent = () => {
    if( title == 'Error') setShowMessage(false)
    else window.location.href = "/"
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();
    
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6LcUsEMoAAAAAA8AApraXZxhHWX_cbkxXJb8LwfZ", { action: 'submit' }).then(token => {
        //if(isVerified){
        setLoading(true);
        //const recaptchaValue = recaptchaRef.current.getValue();
        var form = document.getElementById("contactForm");
        var formData = new FormData(form);
        formData.append('recaptcha_response', token);

        setLoading(true);
        axios.post("https://inmobiplace.com/api/contactMandarina/", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
          }).then(function(response) {

            if(response.data == "FAIL" || response.data == "ERROR") {
              setLoading(false)
              setTitle("Error")
              setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
              handleShow()
            } else {
              setLoading(false)
              setTitle("Enviado")
              setMessage("Hemos recibido tu mensaje, pronto uno de nuestros agentes se comunicará contigo. ¡Gracias!");
              handleShow()
            }

          }).catch(function(err) {
            setLoading(false)
            setTitle("Error")
            setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
            handleShow()
          });
      //} else 
        //alert("Por favor, verifica que no eres un robot")
      });
    });
  }

  const serviceItemChange = ( id ) => {
    setShowServiceItem( true )
    setServiceItemId(id)

    if(id === 1) {
      setServiceItemTitle('Artículos Promocionales')
      setServiceItemDesc('Disponemos de una amplia gama de productos para reforzar la identidad de tu marca y captar la atención del público.')
      setServiceItemImage(promo_d)
      setServiceItemColor('#F48214')
      setServiceItemBackground(item1)
      setServiceItemClose(close1)
    } else if(id === 2) {
      setServiceItemTitle('Proyectos Especiales')
      setServiceItemDesc('Creamos soluciones a medida, para proyectos únicos y personalizados. Trabajamos en conjunto contigo para diseñar y ejecutar proyectos que superen tus expectativas. ')
      setServiceItemImage(proyectos_d)
      setServiceItemColor('#F89101')
      setServiceItemBackground(item2)
      setServiceItemClose(close2)
    } else {
      setServiceItemTitle('Regalos y Kits Empresariales')
      setServiceItemDesc('Ofrecemos diseños innovadores creados para causar una impresión duradera en tus clientes y colaboradores, reflejando de manera objetiva tu marca.')
      setServiceItemImage(regalos_d)
      setServiceItemColor('#B7014C')
      setServiceItemBackground(item3)
      setServiceItemClose(close3)
    }
  }

  return (
    <>
      <Modal show={showServiceItem} size="lg">
        <Container className="service-item-modal" style={{ backgroundImage: `url(${serviceItemBackground}` }}>
          <div className="service-item-close" onClick={ () => setShowServiceItem(false) }> <img src={serviceItemClose} width={40} /> </div>
          <div className="service-item-title" style={{ color: serviceItemColor }}> { serviceItemTitle } </div>
          <br/>
          <div className="service-item-desc"> { serviceItemDesc } </div>
          <br/>
          <div> <img src={serviceItemImage} className="service-item-img" /> </div>
        </Container>
      </Modal>

      <Modal show={showCatalog} className="catalog-conteiner" contentClassName="service-content" size="lg">
        <div align="left" onClick={ () => setShowCatalog(false) }> <img src={ back } className="back-icon"/> </div>
        <br/>
        <Container className="custom-contaner">
          <Row>
            <Col md={12} align="center">
              <div className="catalogo-text"> ¡Explora el catálogo más completo del mercado y contáctanos para una asesoría completa!  </div>
              <br/><br/>
              <div className="catalogo-buttons" style={{ cursor: 'pointer' }} onClick={ () => window.open('https://generalcatalogue2024.eu/7c2a36f43ffef39c29e3b512ab8f8af1/#page/437', '_blank') }> Catálogo </div>
            </Col>
          </Row>
        </Container>
      </Modal>

      <Modal show={showServices} className="service-container" contentClassName="service-content" size="lg">
        <div align="left" onClick={ () => setShowServices(false) }> <img src={ back } className="back-icon"/> </div>
        <Container className="custom-contaner">
          <Row>
            <Col align="center">
              <div className="service-title"> Servicios </div>
              <br/>
              <div className="service-desc"> Ofrecemos servicios para satisfacer todas tus necesidades empresariales: </div>
              <br/>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="service-item" onClick={ () => serviceItemChange(1) }> 
                <div> Artículos Promocionales </div>
                <div> <CaretDownFill /> </div>
              </div>
              <br/>
              <div className="service-product" align="center" onClick={ () => serviceItemChange(1) }> 
                <img src={ promos } className="service-img"/>
              </div>
            </Col>
            <Col md={4}>
              <div className="service-item" onClick={ () => serviceItemChange(2) }> 
                <div> Proyectos Especiales </div>
                <div> <CaretDownFill /> </div>
              </div>
              <br/>
              <div className="service-product" align="center" onClick={ () => serviceItemChange(2) }> 
                <img src={ proyectos } className="service-img"/>
              </div>
            </Col>
            <Col md={4}>
              <div className="service-item" onClick={ () => serviceItemChange(3) }> 
                <div> Regalos y Kits Empresariales </div>
                <div> <CaretDownFill /> </div>
              </div>
              <br/>
              <div className="service-product" align="center" onClick={ () => serviceItemChange(3) }> 
                <img src={ regalos } className="service-img"/>
              </div>
            </Col>
          </Row>
          <br/>
          <br/><br/>
          <Row>
            <Col> <div className="service-desc"> Empleamos las técnicas más sofisticadas para garantizar resultados excepcionales </div> </Col>
          </Row>
          <br/>
          <Row>
            <Col md={ 6 } className="service-tecnique" align="center">
              <div className="service-tecnique-text tec_1">Grabado Laser</div>
            </Col>
            <Col md={ 6 } className="service-tecnique" align="center">
              <div className="service-tecnique-text tec_2">Sublimado</div>
            </Col>
            <Col md={ 6 } className="service-tecnique" align="center">
              <div className="service-tecnique-text tec_3">Serigrafia</div>
            </Col>
            <Col md={ 6 } className="service-tecnique" align="center">
              <div className="service-tecnique-text tec_4">Bordado</div>
            </Col>
          </Row>
        </Container>
      </Modal>

      <header>
        <Modal show={showMessage} onHide={ () => closeModalSent() }>
          <Modal.Header style={{ background: "#fff" }} closeButton>
            <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
          <Modal.Footer style={{ color: "#000", background: "#fff" }}>
            <Button variant="secondary" onClick={ () => closeModalSent() }>
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
        <div>
          <Navbar expand="lg" fixed="top" className="header">
            <Container>
              <Navbar.Brand href="/">
                <Player autoplay loop src={mandarina} className="animation-img">
                  <Controls
                    visible={false}
                    buttons={["play", "repeat", "frame", "debug"]}
                  />
                </Player>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto wave-bg"></Nav>
                <Nav
                  onSelect={handleClick}
                  className="align-items-center wave-bg"
                >
                  <Nav.Link eventKey="refHome" className="nav-link">
                    Inicio
                  </Nav.Link>
                  <Nav.Link onClick={ () => setShowServices(true)} className="nav-link">
                    Servicios
                  </Nav.Link>
                  <Nav.Link eventKey="refUs" className="nav-link">
                    Valores
                  </Nav.Link>
                  <Nav.Link eventKey="refClients" className="nav-link">
                    Clientes
                  </Nav.Link>
                  <Nav.Link onClick={ () => setShowCatalog(true)} className="nav-link">
                    Catálogo
                  </Nav.Link>
                  <Nav.Link eventKey="refContact" className="nav-link">
                    <div className="header-contact">Contacto</div>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>
      { !isMobile && <div style={{ height: '100px', backgroundColor: '#f0f0f0'}}></div> }

      <div className="nosotros-bg" ref={refHome}>
        <div>
          <div style={{ height: isMobile ? 100 : 0 }}></div>
          <Container>
            <Row className="align-items-center">
              <Col md={ 7 } className="nosotros-container">
                <div className="nosotros-titulo">¿Quiénes somos?</div>
                <br/>
                <div className="nosotros-desc"> Somos una marca con más de 10 años de experiencia, especializada en la personalización de artículos promocionales de cualquier tipo, desde los más accesibles, hasta los de categoría premium. Reflejamos la esencia, la identidad y los valores de tu marca, asegurando una conexión duradera y significativa con tu público objetivo. </div>
              </Col>
              <Col md={ 5 } className="background-mobile">
                <Carousel
                  align="center"
                  controls={true}
                  indicators={true}
                  interval={5000}
                  pause={false}
                >
                  <Carousel.Item>
                    <Container className="img-carrusel">
                      <img src={img1} className="img-carrusel" />
                    </Container>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Container className="img-carrusel">
                      <img src={img2} className="img-carrusel" />
                    </Container>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Container className="img-carrusel">
                      <img src={img3} className="img-carrusel" />
                    </Container>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="valores-bg" ref={refUs}>

        <div className="nosotros-titulo" style={{ color: '#F89101' }}>Valores</div>
        <br/><br/><br/>
        <Container>
          <Row>
            <Col md={ 6 } align="center">
              <div className="valores-contanier">
                <div className="valores-title">CALIDAD</div>
                <div> <img src={val1} className="valores-img" /> </div>
                <div className="valores-desc">Aseguramos un alto nivel de excelencia en cada uno de nuestros productos.</div>
              </div>
            </Col>
            <Col md={ 6 } align="center">
              <div className="valores-contanier">
                <div className="valores-title">INNOVACIÓN</div>
                <div> <img src={val2} className="valores-img" /> </div>
                <div className="valores-desc">Reafirmamos nuestro compromiso en el seguimiento de tendencias para el lanzamiento de los productos.</div>
              </div>
            </Col>
            <Col md={ 12 } align="center">
              <div className="valores-contanier">
                <div className="valores-title">EFICIENCIA</div>
                <div> <img src={val3} className="valores-img" /> </div>
                <div className="valores-desc">Disponemos de un gran inventario de productos, que nos permiten contar con una entrega ágil. </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="clients-conteiner" ref={refClients}>
        <div>
          <div className="nosotros-titulo" style={{ color: '#FFBF40' }}>Clientes</div>
          <br/><br/><br/>
          <div>
            <Marquee direction="left" gradient={ !isMobile }>
              <div align="center"><img src={assekuransa} className='marquee-img'/></div>
              <div align="center"><img src={caxi} className='marquee-img' /></div>
              <div align="center"><img src={cedros} className='marquee-img' /></div>
              <div align="center"><img src={cristal} className='marquee-img' /></div>
              <div align="center"><img src={dnatural} className='marquee-img' /></div>
              <div align="center"><img src={little} className='marquee-img' /></div>
              <div align="center"><img src={master} className='marquee-img' /></div>
            </Marquee>
          </div>
          <div style={{ height: '50px' }}></div>
          <div>    
            <Marquee direction="right" gradient={ !isMobile }>
              <div align="center"><img src={materias} className='marquee-img' /></div>
              <div align="center"><img src={mnkey} className='marquee-img' /></div>
              <div align="center"><img src={porton} className='marquee-img'/></div>
              <div align="center"><img src={quest} className='marquee-img' /></div>
              <div align="center"><img src={urbania} className='marquee-img' /></div>
              <div align="center"><img src={wtracking} className='marquee-img' /></div>
            </Marquee>
          </div>
        </div>
      </div>

      <div className="footer-contact-form" ref={refContact}>
        <Container className="section">
          <Row className="row-flex">
            <Col xs={12} md={12}>
              <div>
                <div className="contacto-titulo" style={{ color: '#fff' }}>¡Contáctanos!</div>
                <div>
                  <Form className="form-format" id="contactForm" onSubmit={ handleOnSubmit }>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        required
                        type="text"
                        className="footer-contact-textareas"
                        placeholder="NOMBRE:"
                        name="name"
                        value={ name }
                        onChange={ (e) => setName(e.target.value) }
                      />
                      <br />
                      <Form.Control
                        required
                        type="email"
                        className="footer-contact-textareas"
                        placeholder="CORREO:"
                        name="email"
                        value={ email }
                        onChange={ (e) => setEmail(e.target.value) }
                      />
                      <br />
                      <Form.Control
                        required
                        type="text"
                        pattern="[0-9]{10}"
                        className="footer-contact-textareas"
                        placeholder="TELEFONO:"
                        name="phone"
                        value={ phone }
                        onChange={ (e) => setPhone(e.target.value) }
                      />
                      <br />
                      <Form.Control
                        required
                        as="textarea"
                        className="footer-contact-textareas"
                        placeholder="MENSAJE:"
                        name="comments"
                        value={ comments }
                        onChange={ (e) => setComments(e.target.value) }
                      />
                    </Form.Group>
                    <br/>
                    <div className="div-button">
                      <Button disabled={isLoading} className="button-footer" type="submit" >{isLoading ? 'Enviando…' : 'ENVIAR'}</Button>
                    </div>
                  </Form>
                </div>
                <br />
              </div>
            </Col>
          </Row>
        </Container>

        <br/>
        <Container className="section">
          <Row>
            <Col md={12} style={{ textAlign: "center" }} className="contact-media">
              <a href="https://wa.me/5215580975718" target="_blank" style={{ color: '#000' }}><Whatsapp />&nbsp;&nbsp; 55 8097 5718 </a>
            </Col>

            <Col md={12} style={{ textAlign: "center" }}>
              <Envelope className="social-media"></Envelope>
              <span className="contact-media">
                <a href="mailto:ventas@mandarinapromocionales.mx" style={{ color: '#000' }}>ventas@mandarinapromocionales.mx</a>
              </span>
            </Col>
          
            <Col md={12} className="contact-media" style={{ textAlign: "center" }}>
              <a href="https://www.facebook.com/profile.php?id=100093035026566&mibextid=LQQJ4d" target="_blank" style={{ color: '#000' }}><Facebook className="social-media"></Facebook></a>
              <a href="https://instagram.com/mandarina.promocionales?igshid=MzRlODBiNWFlZA==" target="_blank" style={{ color: '#000' }}><Instagram className="social-media"></Instagram></a>
              <a href="https://www.tiktok.com/@mandarina.promocionales?_t=8fsoHCy6TcD&_r=1" target="_blank" style={{ color: '#000' }}><Tiktok className="social-media"></Tiktok></a>
              <a href="https://www.linkedin.com/company/mandarina-promocionales/?viewAsMember=true" target="_blank" style={{ color: '#000' }}><Linkedin className="social-media"></Linkedin></a>
            </Col>
          </Row>
        </Container>

        <br/>
        <Container style={{ maxWidth: "100%" }}>
          { !isMobile && 
            <Row className="footer-logos-bg align-items-center">
              <Col md={4} className="footer-col">
                <img src={logowhite} className="footer-image" />
              </Col>
              <Col md={4} className="footer-text footer-col">
                <div onClick={ () => setLgShow(true) } style={{ cursor: 'pointer' }}>Aviso de privacidad</div>
              </Col>
              <Col md={4} className="footer-col">
                <div onClick={ () => window.location.href = 'https://grupomebs.com' } style={{ cursor: 'pointer' }}><img src={logogrupo} className="footer-image" /></div>
              </Col>
            </Row>
          }
          { isMobile && 
            <>
              <Row className="footer-logos-bg align-items-center">
                <Col md={4} xs={ 12 } className="footer-text footer-col">
                  <div onClick={ () => setLgShow(true) } style={{ cursor: 'pointer' }}>Aviso de privacidad</div>
                </Col>
              </Row>
              <Row className="footer-logos-bg align-items-center">
                <Col md={4} xs={ 6 } className="footer-col">
                  <img src={logowhite} className="footer-image" />
                </Col>
                <Col md={4} xs={ 6 } className="footer-col">
                  <img src={logogrupo} className="footer-image" />
                </Col>
              </Row>
            </>
          }
        </Container>
      </div>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            AVISO DE PRIVACIDAD
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>MANDARINA PROMOCIONALES, (en lo sucesivo MANDARINA), con domicilio en Jos&eacute; Guadalupe Posada n&uacute;mero 16, colonia Ciudad Sat&eacute;lite, Naucalpan de Ju&aacute;rez, C.P. 53100, Estado de M&eacute;xico es el responsable del uso y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:&nbsp;</p>

          <p>&nbsp;</p>

          <p>Los datos personales que en su caso nos proporcione, los utilizaremos para las siguientes finalidades:&nbsp;</p>

          <p>&nbsp;</p>

          <ol>
            <li>Proveerle los servicios o productos que comercializamos.</li>
            <li>Brindarle los servicios que nos solicite.</li>
            <li>Conocer sus necesidades de productos o servicios para estar en posibilidad de ofrecerle los que m&aacute;s se adecuen a sus preferencias.</li>
            <li>Enviar por cualquier medio f&iacute;sico o electr&oacute;nico (correos electr&oacute;nicos, mensajes de texto, redes sociales, mensajer&iacute;a instant&aacute;nea, etc.), conocido o por conocerse, informaci&oacute;n de car&aacute;cter comercial, de mercadeo y promocional sobre los servicios o promociones desarrolladas por MANDARINA, sus empresas filiales, subsidirias y/o relacionadas, as&iacute; como de sus socios comerciales y/o proveedores.</li>
            <li>Identificaci&oacute;n y contacto.</li>
            <li>Fines estad&iacute;sticos.</li>
            <li>Elaboraci&oacute;n de bases de datos.</li>
            <li>Atender quejas y aclaraciones, y en su caso, tratarlos para fines compatibles con los mencionados en este Aviso de privacidad y que se consideren an&aacute;logos para efectos legales.</li>
          </ol>

          <p>&nbsp;</p>

          <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, podremos solicitarle los siguientes datos personales:</p>

          <ol>
            <li>Nombre.</li>
            <li>Tel&eacute;fono.</li>
            <li>Correo electr&oacute;nico.</li>
          </ol>

          <p>MANDARINA no recaba Datos Personales Sensibles. No obstante, si se diera el caso, se obtendr&aacute; el consentimiento expreso y por escrito del titular para su tratamiento, a trav&eacute;s de su firma aut&oacute;grafa, firma electr&oacute;nica o cualquier mecanismo de autenticaci&oacute;n.</p>

          <p>&nbsp;</p>

          <p>Le informamos que sus datos personales pueden ser compartidos dentro y fuera del pa&iacute;s para las finalidades antes mencionadas, &uacute;nicamente con empresas filiales, subsidirias y/o relacionadas con MANDARINA.</p>

          <p>&nbsp;</p>

          <p>Usted acepta y reconoce que las transferencias anteriormente descritas son necesarias para cumplir con las obligaciones que tiene pactadas MANDARINA con usted por lo que no se requiere su consentimiento para realizar las mismas.</p>

          <p>&nbsp;</p>

          <p>Asimismo, le informamos que para el desarrollo de algunos servicios, productos y/o promociones MANDARINA podr&aacute; requerir la participaci&oacute;n de socios comerciales y/o proveedores a los cuales se les podr&aacute; dar acceso a nuestras bases de datos para finalidades especificas, en el entendido que en todo momento MANDARINA conservar&aacute; la propiedad de dichas bases de datos.</p>

          <p>&nbsp;</p>

          <p>Usted tiene derecho a conocer que datos personales tenemos de usted, para que los utilizamos y las condiciones del uso que les damos (Acceso).&nbsp;</p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p>Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que estar desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no este siendo utilizada adecuadamente (Cancelaci&oacute;n) as&iacute; como oponerse al uso de sus datos personales para fines especif&iacute;cos (Oposici&oacute;n). Estos derechos se conocen como Derechos ARCO.</p>

          <p>&nbsp;</p>

          <p>Para revocar su consentimiento para el tratamiento de sus datos personales y el ejercicio de sus Derechos ARCO, deber&aacute; presentar una solicitud (la &ldquo;Solicitud ARCO&rdquo;), la cual deber&aacute; enviar al correo electr&oacute;nico contacto@mandarinapromocionales.mx, acompa&ntilde;ada de la siguiente informaci&oacute;n y documentaci&oacute;n:</p>

          <p>&nbsp;</p>

          <ul>
            <li>Su nombre, domicilio y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud ARCO.</li>
            <li>Los documentos que acrediten su identidad (copia de IFE, pasaporte o cualquier otra identificaci&oacute;n oficial) o en su caso,los documentos que acrediten su representaci&oacute;n legal.</li>
            <li>Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca ejercer alguno de los Derechos ARCO.</li>
            <li>Cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales.</li>
            <li>En caso de solicitar una rectificaci&oacute;n de datos, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar ladocumentaci&oacute;n que sustente su petici&oacute;n, y la indicaci&oacute;n del lugar donde se podr&aacute;n revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</li>
          </ul>

          <p>&nbsp;</p>

          <p>MANDARINA responder&aacute; su Solicitud ARCO y expresar&aacute;&nbsp; los motivos de su decisi&oacute;n mediante un correo electr&oacute;nico en un plazo m&aacute;ximo de 20 d&iacute;as naturales contados desde el d&iacute;a en que se haya recibido su Solicitud ARCO.&nbsp;</p>

          <p>&nbsp;</p>

          <p>En caso de que la Solicitud ARCO se conteste de manera afirmativa o procedente, los cambios solicitados se har&aacute;n en un plazo m&aacute;ximo de 15 d&iacute;as naturales.&nbsp;</p>

          <p>&nbsp;</p>

          <p>Los plazos antes referidos se podr&aacute;n prorrogar por una sola vez por un periodo igual en caso de ser necesario.</p>

          <p>&nbsp;</p>

          <p>MANDARINA podr&aacute; negar el acceso para que usted ejerza los Derechos ARCO en los siguientes supuestos:</p>

          <p>&nbsp;</p>

          <ul>
            <li>Cuando Usted no sea el titular de los datos personales, o no pueda acreditar la representaci&oacute;n del titular.</li>
            <li>Cuando sus datos personales no obren en las bases de datos de MANDARINA.</li>
            <li>Cuando se lesionen los derechos de un tercero.</li>
            <li>Cuando exista un impedimento legal o la resoluci&oacute;n de una autoridad competente, que restrinja sus Derechos ARCO, y cuando la rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n haya sido previamente realizada.</li>
          </ul>

          <p>&nbsp;</p>

          <p>La negativa podr&aacute; ser parcial, en cuyo caso MANDARINA efectuar&aacute; el acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n en la parte procedente.</p>

          <p>En el caso de revocar el consentimiento que&nbsp; nos haya otorgado para el tratamiento de sus datos personales es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando servicios.</p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p>Con el objetivo de mejorar la experiencia de sus usuarios en sus sitios web, MANDARINA podr&aacute; utilizar &ldquo;cookies&rdquo; y/o &ldquo;web beacons&rdquo;. Las &quot;cookies&quot; son archivos de texto que son descargados autom&aacute;ticamente y almacenados en el disco duro del equipo de c&oacute;mputo del usuario al navegar en una p&aacute;gina de Internet para recordar algunos datos sobre este usuario, entre ellos, sus preferencias para la visualizaci&oacute;n de las p&aacute;ginas en ese servidor, nombre y contrase&ntilde;a. Por su parte, las &quot;web beacons&quot; son im&aacute;genes insertadas en un p&aacute;gina de Internet o correo electr&oacute;nico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar informaci&oacute;n sobre la direcci&oacute;n IP del usuario, duraci&oacute;n del tiempo de interacci&oacute;n en dicha p&aacute;gina y el tipo de navegador utilizado, entre otros. Le informamos que utilizamos &quot;cookies&quot; y &quot;web beacons&quot; con fines &uacute;nicamente de marketing, experiencia de usuario y medici&oacute;n, para obtener informaci&oacute;n como la siguiente:</p>

          <p>&nbsp;</p>

          <ul>
            <li>Su tipo de navegador y sistema operativo;</li>
            <li>Las p&aacute;ginas de Internet que visita;</li>
            <li>Los v&iacute;nculos que sigue;</li>
            <li>La direcci&oacute;n IP; y el sitio que visit&oacute; antes de entrar al nuestro.</li>
          </ul>

          <p>&nbsp;</p>

          <p>Estas cookies y otras tecnolog&iacute;as pueden ser deshabilitadas. Para conocer c&oacute;mo hacerlo, consulte el men&uacute; de ayuda de su navegador. Tenga en cuenta que, en caso de desactivar las &quot;cookies&quot;, es posible que no pueda acceder a ciertas funciones personalizadas en nuestro sitio web.</p>

          <p>&nbsp;</p>

          <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras praticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>

          <p>&nbsp;</p>

          <p>El sitio de internet https://grupoMANDARINA.com/ puede contener enlaces a otros sitios web, respecto de los cuales MANDARINA no se hace responsable de las pr&aacute;cticas y usos de los otros sitios, incluyendo los de nuestros patrocinadores y/o socios comerciales</p>

          <p>&nbsp;</p>

          <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s de nuestro sitio web https://mandarinapromocionales.mx/.</p>

          <p>&nbsp;</p>

          <p>El Responsable cuenta con medidas de seguridad f&iacute;sicas, administrativas y t&eacute;cnicas para resguardar sus Datos Personales. No obstante, en caso de que ocurra alguna vulneraci&oacute;n a la seguridad que afecte de forma significativa sus derechos patrimoniales o morales, el Responsable lo har&aacute; de su conocimiento mediante el correo electr&oacute;nico que proporcion&oacute;, a fin de que usted pueda tomar las medidas necesarias correspondientes para la defensa de los mismos.</p>

          <p>&nbsp;</p>

          <p>En el momento que usted se registra en nuestro portal o nos proporciona sus datos personales usted manifiesta su consentimiento expreso para el tratamiento de sus datos personales de conformidad con este Aviso de Privacidad.</p>

        </Modal.Body>
      </Modal>
    </>
  );
}
export default Home;

import { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import logo from './img/whats_profile.jpg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  Facebook, Instagram,  Linkedin, Tiktok,Envelope,
  Whatsapp
}
  from 'react-bootstrap-icons';
    
import contact from './img/contacto.png'
import logowhite from './img/logofooter.png'
import logogrupo from './img/grupofooter.png'
import Home from './pages/Home';
import Catalogo from './pages/Catalogo';



function App() {
  
  const [path, setPath] = useState(window.location.pathname)

  useEffect(() => {
    soap()
  }, []);

  const handleClick = (eventKey) => {

    if( path == "/" || eventKey == "refContact" ) {
      eval(eventKey).current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    } else 
        window.location.href = "/"
  };

  const soap = () => {

    /*axios({
      method: 'get',
      url: "https://inmobiplace.com/srv/mandarinasoap.do",
      headers: { 'content-type': 'application/json' },
    })
    .then(result => {
      console.log("result xml", result.data)
      var txt = "";
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString((result.data).toString(),"text/xml");
      var x = xmlDoc.getElementsByTagName("return");
      for (var i = 0; i < x.length; i++) {
          txt += x[i].childNodes[0].nodeValue;
      }

      console.log('parsed', JSON.parse(txt))

    })
    .catch(error => {
      console.log("error", error.message)
    })*/
    /*var xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', 'https://ws.innovation.com.mx/index.php', true);
    var sr =
      '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">' +
          '<soap:Body>' +
            '<Validate>' +
              '<user_api>q14532Rdascfdff344qd456Hwededfsdqstz</user_api>' +
              '<api_key>sFtz-58DDq17yt5Sa</api_key>' +
              '<format>JSON</format>' +
            '</Validate>' +
          '</soap:Body>' +
        '</soap:Envelope>';

    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == 4) {
            if (xmlhttp.status == 200) {
              var xmlDoc = xmlhttp.responseXML;
              var txt = "";
              var x = xmlDoc.getElementsByTagName("return");
              for (var i = 0; i < x.length; i++) {
                  txt += x[i].childNodes[0].nodeValue;
              }
              console.log("result", JSON.parse(txt))
            }
        }
    }
    xmlhttp.setRequestHeader('Content-Type', 'text/xml');*/
    //xmlhttp.setRequestHeader('Accept', '*/*');
    /*xmlhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xmlhttp.setRequestHeader('Access-Control-Allow-Origin', '*');

    xmlhttp.send(sr);*/
  }


  return (
    <>
        <FloatingWhatsApp
          phoneNumber="5215580975718"
          accountName="Mandarina Promocionales"
          className="wa-style"
          statusMessage="En linea"
          avatar={ logo }
          chatMessage="Hola, ¡Bienvenido a Mandarina Promocionales! ¿Cómo podemos ayudarte?"
          allowClickAway
          notification={false}
        />
     
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='home' element={<Home />} />
          <Route path='catalogo' element={<Catalogo/>}/>
        </Routes>
      </BrowserRouter>
     
      </>
  );
}

export default App;

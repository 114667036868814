import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Facebook, Instagram,  Linkedin, Tiktok,Envelope, Whatsapp } from 'react-bootstrap-icons';
import "../App.css";
import house, { House } from 'react-bootstrap-icons'
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import logoanim from "../img/logoanimado.png";
import contact from '../img/contacto.png'
import logowhite from '../img/logofooter.png'
import logogrupo from '../img/grupofooter.png'

import { Player, Controls } from '@lottiefiles/react-lottie-player';
import mandarina from '../img/animations/mandarina.json'

function Catalogo() {
  const [activeTab, setActiveTab] = useState("home");
  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };
  return (
    <>
      <div className="cat-header">
        <Container>
          <Row className="cat-header align-items-center">
            <Col md={2}>México Marketplace </Col>
            <Col md={2} className="col-cat">
              Contacto ventas
            </Col>
            <Col md={4}></Col>
            <Col md={2}>Contacto ventas</Col>
            <Col md={2} className="col-cat">
              Favoritos (0)
            </Col>
          </Row>
        </Container>
        <div style={{ height: "20px" }}></div>
      </div>
      
      <Container align="left">
        <Row>
          <Col md={ 4 }>
            <Player autoplay loop src={ mandarina } className='animation-img-big'>
              <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
          </Col>
        </Row>
      </Container>
      
      <Container>
        <Tabs
          activeKey={activeTab}
          onSelect={handleSelect}
                  className="menu-tabs your-menu-tabs"
                  fill
        >
          <Tab
            eventKey="home"
            title={<House className="house-cat"/>}
          />
          <Tab eventKey="tecnologicos" title="Tecnológicos" />
          <Tab
            eventKey="backToSchool"
                      title={<span className="menu-label">Back to school <span className="nuevo-label">Nuevo</span></span>}
            className="menu-tab"
          >
            
          </Tab>
          <Tab eventKey="verano"  title={<span className="menu-label">Verano  <span className="nuevo-label">Nuevo</span></span>} >
           
          </Tab>
          <Tab eventKey="novedades" title={<span className="menu-label">Novedades 2023<span className="nuevo-label">Nuevo</span></span>}>
            <span className="new-label">Nuevo</span>
          </Tab>
          <Tab eventKey="pendrives" title="Pendrives" />
          <Tab eventKey="catalogos" title="Catálogos" />
          <Tab eventKey="ecologicos" title="Ecológicos" />
        </Tabs>
      </Container>


      <div  className='footer-contact-form'>  
      <Container className="section">
        <Row className='row-flex'>
          <Col xs={ 12 } md={6}>
            <div>
              <div>¡Contáctanos!</div>
              <div >
                <Form className='form-format'>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Control type="text" className='footer-contact-textareas' placeholder="NOMBRE:" /><br/>
                  <Form.Control type="email" className='footer-contact-textareas' placeholder="CORREO:" /><br/>
                  <Form.Control type="text" pattern="[0-9]{10}" className='footer-contact-textareas' placeholder="TELEFONO:" /><br/>
                  <Form.Control as="textarea" className='footer-contact-textareas' placeholder="MENSAJE:"  />
                </Form.Group> 
                <div className='div-button'>
                  <Button className='button-footer' >Enviar</Button>{' '}
                </div>
                </Form>
              </div>
              <br />
              <Container >
                <Row>
                <Col md={6} style={{ textAlign: 'left' }} className='contact-media'><Whatsapp></Whatsapp>  55798758976</Col>
                <Col md={6} className='contact-media'>
                  <Facebook className='social-media'></Facebook>
                  <Instagram className='social-media'></Instagram>
                  <Tiktok className='social-media'></Tiktok>
                  <Linkedin className='social-media'></Linkedin>
                </Col>
                </Row>
                <Row>
                  <Col style={{ textAlign: 'center' }}>
                    <Envelope className='social-media'></Envelope>
                    <span className='contact-media'>contacto@madarinapromocionales.mx</span>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
          <Col xs={ 12 }  md={6} className="footer-contact-contain"></Col>
        </Row>
      </Container>

      <Container style={{ maxWidth: '100%' }}>
        <Row className='footer-logos-bg align-items-center'>
          <Col md={4}><img src={logowhite} className="footer-image"/></Col>
          <Col md={4} className='footer-text'>Aviso de privacidad</Col>
          <Col md={4}><img src={logogrupo} className="footer-image"/></Col>
        </Row>
      </Container>

         
      </div>

    </>
  );
}
export default Catalogo;
